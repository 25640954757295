<template>
   <BT-Blades
    :anchorBlades="[{ bladeName: 'order-sheets' }]"
    bladeClass="pa-2"
    :maximizedCount="10">
        <template v-slot="bladesData">
            <Order-Sheets-Blade :bladesData="bladesData" />
            <Order-Sheet-Blade :bladesData="bladesData" />
            <Standing-Orders-Schedule-Blade :bladesData="bladesData" />
            <Customer-Orders-Blade :bladesData="bladesData" />
            <Customer-Order-Blade :bladesData="bladesData" />
            <Standing-Customer-Orders-Blade :bladesData="bladesData" />
            <Standing-Customer-Order-Blade :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Sales-Hub',
    components: {
        OrderSheetBlade: () => import('~home/order-sheets/Order-Sheet-Blade.vue'),
        OrderSheetsBlade: () => import('~home/order-sheets/Order-Sheets-Blade.vue'),
        CustomerOrdersBlade: () => import('~home/customer-orders/Customer-Orders-Blade.vue'),
        CustomerOrderBlade: () => import('~home/customer-orders/Customer-Order-Blade.vue'),
        StandingCustomerOrdersBlade: () => import('~home/standing-orders/Standing-Customer-Orders-Blade.vue'),
        StandingCustomerOrderBlade: () => import('~home/standing-orders/Standing-Customer-Order-Blade.vue'),
        StandingOrdersScheduleBlade: () => import('~home/standing-orders/Standing-Orders-Schedule-Blade.vue')
    },
    data: function() {
        return {
            bladesData: null
        }
    }
}
</script>